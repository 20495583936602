import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'

import 'alpinejs'
import 'tailwindcss/tailwind.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/900.css'

import 'controllers'

require.context('../images', true)

Rails.start()
ActiveStorage.start()
Turbolinks.start()
