import { Controller } from 'stimulus'

import Swiper, { Navigation } from 'swiper'

import 'swiper/swiper-bundle.css'

Swiper.use(Navigation)

export default class extends Controller {
  static targets = ['container', 'next', 'prev']

  connect () {
    new Swiper(this.containerTarget, {
      loop: true,
      slidesPerView: 1.25,
      centeredSlides: true,
      spaceBetween: 32,
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget
      },
      breakpoints: {
        768: {
          slidesPerView: 2.5
        },
        1024: {
          slidesPerView: 3.5
        }
      }
    })
  }
}
