import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation } from 'swiper'

import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation])

export default class extends Controller {
  static targets = ['next', 'prev']

  connect() {
    new Swiper(this.element, {
      loop: true,
      slidesPerView: 1.5,
      spaceBetween: 0,
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget
      },
      breakpoints: {
        768: {
          slidesPerView: 2.5
        },
        1366: {
          slidesPerView: 3.5
        }
      },
      slideToClickedSlide: true,
      autoplay: {
        delay: 4000,
      },
    })
  }
}
