import { Controller } from 'stimulus'

import Swiper, { Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use(Autoplay)

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      autoplay: {
        delay: 10000,
      },
      loop: true,
      slidesPerView: 1.25,
      spaceBetween: 16,
      breakpoints: {
        768: {
          slidesPerView: 1.5,
          spaceBetween: 24,
        },
        1024: {
          slidesPerView: 2.5,
        },
      },
    })
  }
}
