import { Controller } from 'stimulus'

import Swiper, { EffectFade, Pagination } from 'swiper'

import 'swiper/swiper-bundle.css'

Swiper.use([Pagination, EffectFade])

export default class extends Controller {
  static targets = ['pagination']

  connect () {
    new Swiper(this.element, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 32,
      effect: 'fade',
      pagination: {
        el: this.paginationTarget,
        clickable: true
      }
    })
  }
}
